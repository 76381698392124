export const REQUESTING = 'requesting';
export const EXISTING = 'existing';
export const APPROVE = 'APPROVE';
export const DENY = 'DENY';
export const MODIFY = 'MODIFY';
export const REVOKE = 'REVOKE';
export const REINSTATE = 'REINSTATE';
export const ACTIVE = 'Active';
export const USER_SEARCH_REQUEST = '@userAdmin/USER_SEARCH_REQUEST';
export const USER_SEARCH_SUCCESS = '@userAdmin/USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAIL = '@userAdmin/USER_SEARCH_FAIL';
export const USER_ACCESS_REQUEST = '@userAdmin/USER_ACCESS_REQUEST';
export const USER_ACCESS_SUCCESS = '@userAdmin/USER_ACCESS_SUCCESS';
export const USER_ACCESS_FAIL = '@userAdmin/USER_ACCESS_FAIL';
export const UPDATE_SEARCH_TERM = '@userAdmin/UPDATE_SEARCH_TERM';
export const UPDATE_REQ_ITEMS = '@userAdmin/UPDATE_REQ_ITEMS';
export const UPDATE_REQ_PAGINATION = '@userAdmin/UPDATE_REQ_PAGINATION';
export const UPDATE_REQ_SORT = '@userAdmin/UPDATE_REQ_SORT';
export const UPDATE_EXT_ITEMS = '@userAdmin/UPDATE_EXT_ITEMS';
export const UPDATE_EXT_PAGINATION = '@userAdmin/UPDATE_EXT_PAGINATION';
export const UPDATE_EXT_SORT = '@userAdmin/UPDATE_EXT_SORT';
export const CHANGE_TAB = '@userAdmin/CHANGE_TAB';
export const SHOW_ERROR = '@userAdmin/SHOW_ERROR';
export const HIDE_ERROR = '@userAdmin/HIDE_ERROR';
export const SHOW_USER_MODAL = '@userAdmin/SHOW_USER_MODAL';
export const HIDE_USER_MODAL = '@userAdmin/HIDE_USER_MODAL';
export const SET_USER_TO_EDIT = '@userAdmin/SET_USER_TO_EDIT';
export const USER_EDIT_REQUEST = '@userAdmin/USER_EDIT_REQUEST';
export const USER_EDIT_SUCCESS = '@userAdmin/USER_EDIT_REQUEST';
export const USER_REVOKE_REQUEST = '@userAdmin/USER_REVOKE_REQUEST';
export const USER_REVOKE_SUCCESS = '@userAdmin/USER_REVOKE_REQUEST';
export const USER_REINSTATE_REQUEST = '@userAdmin/USER_REINSTATE_REQUEST';
export const USER_REINSTATE_SUCCESS = '@userAdmin/USER_REINSTATE_REQUEST';
