import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import dummy from './../../images/dummy.png';

const createOption = label => ({
  label,
  value: label,
  IsManual: true,
});

const customStyles = {
  menu: provided => ({
    ...provided,
    width: '400px',
  }),
};

const MyCreatableSelect = ({ value, handleSelectChange, t, submitting }) => {
  const [inputValue, setInputValue] = useState('');
  const [abortController, setAbortController] = useState(null);
  const [options, setOptions] = useState([]);

  const handleKeyDown = event => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleSelectChange([...value, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const CustomOption = ({ innerProps, label, data }) => {
    return (
      <div {...innerProps} id="psi-artist-item" className="psi-artist-item">
        <div className="img-name-detl">
          <div>
            <img src={data.imageUrl ? data.imageUrl : dummy} alt="" width={50} height={50} />
          </div>
          <div className="art-details">
            <span>{label}</span>
            <span className="art-item-gray-col">{data.value}</span>
          </div>
        </div>
        <div className="art-details">
          <span className="art-item-gray-col">{data.projectsCount} Projects</span>
          <span className="art-item-gray-col">{data.resourcesCount} Resources</span>
        </div>
      </div>
    );
  };

  const promiseOptions = async inputValue => {
    if (inputValue.length > 2)
      return new Promise(resolve => {
        const fetchHeaders = new Headers({
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('accessToken'),
        });

        if (abortController) {
          abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        fetch(window.env.api.url + `/media/api/partyservice?artistname=${inputValue}`, {
          method: 'GET',
          headers: fetchHeaders,
          signal: newAbortController.signal,
        })
          .then(response => {
            return response.json();
          })
          .then(responseJSON => {
            const options = responseJSON.records.map(item => ({
              value: item.id ? item.id.toString() : item.id,
              label: item.name,
              imageUrl: item.imageUrl,
              projectsCount: item.projectsCount,
              resourcesCount: item.resourcesCount,
            }));
            resolve(options);
            setOptions(options);
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {});
      });
  };

  const handleCreateOption = inputValue => {
    const newOption = {
      label: inputValue,
      value: inputValue,
      IsManual: true,
    };

    handleSelectChange([...value, newOption]);
    return newOption;
  };

  const required = value && value.length === 0 ? true : false;

  return (
    <div>
      <AsyncCreatableSelect
        className={required && submitting ? 'brd-red' : ''}
        cacheOptions
        defaultOptions={options}
        isMulti
        closeMenuOnSelect={false}
        loadOptions={promiseOptions}
        components={{ Option: CustomOption }}
        inputValue={inputValue}
        isClearable
        onChange={newValue => handleSelectChange(newValue)}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        value={value}
        styles={customStyles}
        closeOnSelect={false}
        onSelectResetsInput={false}
        onCreateOption={handleCreateOption}
      />
      {required && submitting && (
        <div id="artist-error" className="invalid-tooltip msh-req-error">
          {t('releaseInfo:ArtistRequired')}
        </div>
      )}
    </div>
  );
};

export default MyCreatableSelect;
