import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Toggle from '../../component_library/Toggle';
import './BlockingPoliciesModal.css';

class InGroovesModal extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      projectID: '',
    };

    this.state = {
      show: false,
    };
  }

  handleShow() {
    this.setState({ show: true });
  }

  // componentDidMount() {
  //   console.log('ejnewwfwnk-----------+++');
  //   if (
  //     this.props.match &&
  //     this.props.match.params &&
  //     this.state.projectID !== this.props.match.params.projectID
  //   ) {
  //     this.setState({ projectID: this.props.match.params.projectID });
  //   }
  //   this.handleShow();
  // }

  render() {
    const { t } = this.props;
    return (
      <Modal id="BlockingPolicies" show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {t('blocking:popupmessage')}
           */}
          <div className="alert alert-danger clearfix" style={{ textAlign: 'left' }} role="alert">
            <span>{t('review:InGroovesHeader')}</span>
            <br />
            <span>
              {t('review:InGroovesSubHeader')}
              <i>{t('review:InGroovesHeaderItalic')}</i>
            </span>
          </div>
          <div
            style={{ border: '2px solid #894149', color: '#000', textAlign: 'left' }}
            className="alert alert-light clearfix"
            role="alert"
          >
            <i style={{ color: '#894149' }}>{t('review:InGroovesBodyHeader')}</i>

            <p style={{ marginTop: '5px' }}>{t('review:InGroovesBodyContent')}</p>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <div>
                <span style={{ marginRight: '20px' }}>{t('review:InGroovesLabel')}</span>
                <span style={{ padding: '10px' }}>
                  {this.props.isInGrooves
                    ? t('review:InGroovesYesLabel')
                    : t('review:InGroovesNoLabel')}{' '}
                </span>
              </div>
              <div style={{ marginTop: '-4px' }}>
                <Toggle
                  defaultChecked={this.props.isInGrooves}
                  onChange={this.props.handleIngrooves}
                />
              </div>
            </div>
            <br />
            <p>{t('review:InGroovesBodyFooter')}</p>
          </div>
          <span style={{ float: 'right' }}>
            <br />
            <Button
              variant="primary"
              style={{ background: '#999999' }}
              id="yesButton"
              onClick={this.props.handleClose}
            >
              {t('review:Cancel')}
            </Button>
            <Button
              style={{ padding: '5px' }}
              variant="primary"
              id="noButton"
              onClick={this.props.handleSubmitProjectClick}
            >
              {t('review:SaveProject')}
            </Button>
          </span>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(InGroovesModal);
