import React, { useEffect, useState } from 'react';
import { Table, Modal } from 'react-bootstrap';
import TakeDownExtendedTracks from '../../components/pages/FindProject/pageComponents/TakeDownExtendedTracks';
import './takedownRePublish.css';
import Api from 'lib/api';
import moment from 'moment';
import inGrrovesIcon from '../../images/inGrrovesIcon.png';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { showNotyError, showNotySucess } from 'components/Utils';

export default function TakeDownRePublishModal(props) {
  const [project, setProject] = useState(null);
  const [isTrackExists, setIsTrackExists] = useState(false);
  useEffect(() => {
    setProject({ ...props.project });
  }, [props]);

  useEffect(() => {
    if (project) {
      setIsTrackExists(isTrackLisExists(project));
    }
  }, [project]);

  const handleRepublish = (data, trackId, newIsPublish) => {
    const modifiedProjectDiscs = data.Discs.map(disc => ({
      ...disc,
      Tracks: disc.Tracks.map(track =>
        track.trackID === trackId ? { ...track, isRepublish: newIsPublish } : track,
      ),
    }));
    setProject({ ...data, Discs: modifiedProjectDiscs });
  };

  const checkProjectStepStatus = stepStatus => {
    return stepStatus ? (
      <i className="material-icons success">verified_user</i>
    ) : (
      <i className="material-icons">block</i>
    );
  };
  const checkProjectStepAssetStatus = stepStatus => {
    return stepStatus ? (
      <i className="material-icons success">verified_user</i>
    ) : (
      <i className="material-icons warning">report_problem</i>
    );
  };
  const isTrackLisExists = project1 => {
    let trackIdList = [];
    project1 &&
      project1.Discs.forEach(disc => {
        disc.Tracks.forEach(track => {
          if (track.isRepublish) {
            trackIdList.push(track.trackID);
          }
        });
      });
    return !trackIdList.length > 0;
  };
  const handleRepublishSubmit = () => {
    let trackIdList = [];
    project.Discs.forEach(disc => {
      disc.Tracks.forEach(track => {
        if (track.isRepublish) {
          trackIdList.push(track.trackID);
        }
      });
    });
    const convertedTrackList = trackIdList.map(Number);
    const payload = {
      ProjectIds: [project.projectID],
      PublishTrackIds: convertedTrackList,
      languageCode: 'en',
    };
    props.handleClose();
    if (trackIdList)
      Api.post('/Publish/RePublish', payload)
        .then(response => response.json())
        .then(res => {
          const formatMessage =
            trackIdList.length > 0 && trackIdList.length === 1
              ? `You have successfully republished 1 track.`
              : `
        You have successfully republished ${trackIdList.length} tracks.`;
          if (res.Result) {
            showNotySucess(formatMessage);
          }
          props.refreshProject();
        })
        .catch(err => {
          console.log('error ', err);
          showNotyError(err.message);
        });
  };

  const trackDataTable = trackData => {
    const hasShortTracks = props.hasShortTracksInMultipleDiscs(project.Discs);
    let colour = '';
    if (project.projectReleaseDate && project.projectCreatedDate) {
      if (
        moment(project.projectReleaseDate).format('YYYY-MM-DD') <=
        moment(project.projectCreatedDate).format('YYYY-MM-DD')
      ) {
        colour = 'gray-shade';
      }
    }
    return (
      <Table className="responsive takedownProject" style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <th> {props.t('search:LastUpdate')}</th>
            <th>{props.t('search:ProjectTitle')}</th>
            <th> {props.t('search:Artist')}</th>
            <th> {props.t('search:Label')}</th>
            <th> {props.t('search:Status')}</th>
            <th>{props.t('search:HasAudio')}</th>
            <th>{props.t('search:HasRights')}</th>
            <th>{props.t('search:HasBlocking')}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{project.projectLastModified}</td>
            <td>{project.projectTitle}</td>
            <td>{project.projectArtistName}</td>
            <td>{project.projectReleasingLabel}</td>
            <td>{project.status}</td>

            {/* <td className="status text-center">
              {checkProjectStepStatus(project.isAudioFilesComplete)}
            </td> */}
            <td className={`status text-center ${colour}`}>
              {checkProjectStepAssetStatus(hasShortTracks)}
            </td>
            {/* <td className="status text-center">
              {' '}
              {checkProjectStepStatus(project.isTerritorialRightsComplete)}
            </td> */}
            <td className={`status text-center ${colour}`}>
              {props.isAdmin && project.isInGrooves && (
                <img src={inGrrovesIcon} width={20} alt="inGrooves" />
              )}
              {!project.isInGrooves && checkProjectStepStatus(project.isTerritorialRightsComplete)}
            </td>
            {/* <td className="status text-center">
              {' '}
              {checkProjectStepStatus(project.isBlockingPoliciesComplete)}
            </td> */}
            <td className="status text-center">
              {project.isBlockingPoliciesChanged ? (
                <i class="bi bi-exclamation-diamond-fill warning"></i>
              ) : (
                checkProjectStepStatus(project.isBlockingPoliciesComplete)
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <Modal
      scrollable={true}
      dialogClassName="takedown-modal-70w"
      close
      id="updateModal"
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title> {props.t('track:TakeDownTitle')}</Modal.Title>
        <p className="sub-title"> {props.t('track:TakeDownSubTitle')}</p>
      </Modal.Header>

      <Modal.Body>
        {project && trackDataTable()}
        {project && (
          <TakeDownExtendedTracks
            discs={project.Discs}
            project={project}
            setProject={setProject}
            onIsPublishToggle={handleRepublish}
          />
        )}

        <div className="submit-buttons float-right">
          <Button variant="secondary" onClick={props.handleClose}>
            {props.t('track:Cancel')}
          </Button>

          <Button disabled={isTrackExists} variant="primary" onClick={handleRepublishSubmit}>
            {props.t('track:Republish')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
