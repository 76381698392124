import French from './flags/french.png';
import German from './flags/german.png';
import English from './flags/US.png';
import Spanish from './flags/spanish.png';
const images = {
  French,
  German,
  English,
  Spanish,
};
export default images;
