import React, { useEffect } from 'react';
import '../../FindProject/pageComponents/ExtendedTracks.css';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../../component_library/Toggle';
import _ from 'lodash';

import LoadingImg from '../../../../component_library/LoadingImg';

const Tracks = ({
  tracks,
  project,
  onIsPublishToggle,
  extendedTrackIds,
  expandTracks,
  takedownRightsInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Table className="ext-tracks-table" responsive>
      <thead>
        <tr>
          <th>{project.mediaType !== 'Video' ? t('track:TrackTitle') : t('track:VideoTitle')}</th>
          <th>{t('track:ISRC')}</th>
          <th>{t('track:Artist')}</th>

          <th>{t('track:ReleaseDate')}</th>
          <th className="status text-center">{t('track:HasRights')}</th>
          <th>{t('track:Republish')}</th>
        </tr>
      </thead>
      <tbody>
        {tracks.map((track, i) => {
          const rightsData = takedownRightsInfo.filter(rights => track.isrc === rights.Isrc);

          return (
            <React.Fragment key={track.trackID}>
              <tr>
                <td>{track.trackTitle}</td>
                <td>{track.isrc}</td>
                <td>{track.artist}</td>

                <td>{track.trackReleaseDate || t('track:TBD')}</td>

                <td className="status text-center">
                  {rightsData.length > 0 && rightsData[0].NonExclusiveRight ? (
                    <i className="material-icons warning">report_problem</i>
                  ) : (
                    <i className="material-icons success">verified_user</i>
                  )}
                </td>
                <td className="status text-center">
                  {rightsData.length > 0 && (
                    <Toggle
                      defaultChecked={rightsData[0].NonExclusiveRight ? true : track.isRepublish}
                      onChange={() => onIsPublishToggle(project, track.trackID, !track.isRepublish)}
                      disabled={!rightsData[0].NonExclusiveRight}
                    />
                  )}
                </td>
              </tr>
              {/* {extendedTrackIds.includes(track.trackID) ? (
                <ExtendDeliveryInfo
                  track={track}
                  orderPartnerDetails={dwsData[0].OrderPartnerDetails}
                />
              ) : null} */}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

const Discs = ({
  discs,
  project,
  onIsPublishToggle,
  extendedTrackIds,
  expandTracks,
  takedownRightsInfo,
}) => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey={discs[0].discNumber}>
      {discs.map(disc => (
        <Tab
          key={disc.discNumber}
          eventKey={disc.discNumber}
          title={t('track:Disc') + ' ' + disc.discNumber}
        >
          <Tracks
            tracks={disc.Tracks}
            project={project}
            expandTracks={expandTracks}
            extendedTrackIds={extendedTrackIds}
            onIsPublishToggle={onIsPublishToggle}
            takedownRightsInfo={takedownRightsInfo}
          />
        </Tab>
      ))}
    </Tabs>
  );
};

export default ({ discs, project, setProject, onIsPublishToggle, expandedProjectIds }) => {
  const [extendedTrackIds, setExtendedTrackIds] = React.useState([]);
  const [takedownRightsInfo, setTakedownRightsInfo] = React.useState([]);
  const [loading, seLoading] = React.useState(false);

  const expandTracks = trackId => {
    if (!extendedTrackIds.includes(trackId)) {
      setExtendedTrackIds([...extendedTrackIds, trackId]);
    } else {
      const tracks = extendedTrackIds.filter(id => id !== trackId);
      setExtendedTrackIds(tracks);
    }
  };
  const addIsRepublishProp = track => {
    const rightsData = takedownRightsInfo.filter(rights => track.isrc === rights.Isrc);

    return rightsData[0].NonExclusiveRight;
  };
  useEffect(() => {
    if (takedownRightsInfo.length > 0) {
      let modifiedDiscs = project.Discs;
      modifiedDiscs =
        project &&
        project.Discs.map(disc => ({
          ...disc,
          Tracks: disc.Tracks.map(track => ({
            ...track,
            isRepublish: addIsRepublishProp(track),
          })),
        }));
      setProject({ ...project, Discs: modifiedDiscs });
    }
  }, [takedownRightsInfo]);

  useEffect(() => {
    (async () => {
      let isrcList = [];
      discs.forEach(disc => {
        const isrc = _.map(disc.Tracks, 'isrc');
        isrcList = [...isrcList, ...isrc];
      });
      seLoading(true);
      const fetchHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('accessToken'),
      });

      const fetchBody = JSON.stringify(isrcList);
      try {
        const response = await fetch(
          window.env.api.url + '/media/api/Rights/CheckTrackIsrcRights',
          {
            method: 'POST',
            headers: fetchHeaders,
            body: fetchBody,
          },
        );
        const res = await response.json();

        setTakedownRightsInfo(res);

        seLoading(false);
      } catch (err) {
        console.log(err, 'Error in CheckRights API');
        seLoading(false);
      }
    })();
  }, []);

  const renderTracks = () => {
    if (discs.length > 1) {
      return (
        <Discs
          discs={discs}
          project={project}
          extendedTrackIds={extendedTrackIds}
          expandTracks={expandTracks}
          onIsPublishToggle={onIsPublishToggle}
          takedownRightsInfo={takedownRightsInfo}
        />
      );
    } else {
      return (
        <Tracks
          tracks={discs[0].Tracks}
          project={project}
          extendedTrackIds={extendedTrackIds}
          expandTracks={expandTracks}
          onIsPublishToggle={onIsPublishToggle}
          takedownRightsInfo={takedownRightsInfo}
        />
      );
    }
  };

  return (
    <Table className="responsive">
      <tr className="wrapper-tr">
        <td colSpan={14}>
          <LoadingImg show={loading} />
          <div className="ext-tracks-wrapper">{renderTracks()}</div>
        </td>
      </tr>
    </Table>
  );
};
