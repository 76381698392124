import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Toggle from '../../component_library/Toggle';
class SubmitProjectModal extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <Modal id="SubmitProjectModal" show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Body>
          <div className="alert alert-danger" role="alert">
            {t('review:AreYouSureMain')} <i>{t('review:AreYouSureSub')}</i>
          </div>

          {this.props.showIngroovemsg && (
            <div
              style={{
                border: '2px solid #894149',
                padding: '10px',
                color: '#000',
                textAlign: 'left',
                marginBottom: '15px',
              }}
            >
              <i style={{ color: '#894149' }}>{t('review:InGroovesBodyHeader')}</i>

              <p style={{ marginTop: '5px' }}>{t('review:InGroovesBodyContent')}</p>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div>
                  <span style={{ marginRight: '20px' }}>{t('review:InGroovesLabel')}</span>
                  <span style={{ padding: '10px' }}>
                    {this.props.isInGrooves
                      ? t('review:InGroovesYesLabel')
                      : t('review:InGroovesNoLabel')}{' '}
                  </span>
                </div>
                <div style={{ marginTop: '-4px' }}>
                  <Toggle
                    defaultChecked={this.props.isInGrooves}
                    onChange={this.props.handleIngrooves}
                  />
                </div>
              </div>
              <br />
              <p>{t('review:InGroovesBodyFooter')}</p>
            </div>
          )}

          <div className="submit-buttons float-right">
            <Button variant="secondary" onClick={this.props.handleClose}>
              {t('review:Cancel')}
            </Button>
            <Button variant="primary" onClick={this.props.handleSubmitProjectClick}>
              {t('review:SubmitProject')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(SubmitProjectModal);
